import React from "react";
import { Link, useLocation } from "react-router-dom";
import PracticeLinesImage from "../../assets/images/practice_lines.png";

const ScheduleDemo = () => {
  const location = useLocation()
  const {pathname}  = location;
  console.log("-*-*",pathname);
  return (
    <>
   { pathname !== "/schedule-demo" ?
   <section
    className="section-pading schedule_demo_section section_padding"
    style={{
      backgroundImage: "url(" + PracticeLinesImage + ")",
    }}
  >
    <div className="container">
      <div className="align-items">
        <div className="col-sm-7">
          <h2>
          Experience the Future of Healthcare: Schedule Your Demo and Unleash the Possibilities!
          </h2>
        </div>
        <div className="col-sm-5">
          <Link to="/schedule-demo">
            <div className="home-banner-bolton Schedule_buton_footer cursor-pointer">
              Schedule a demo&nbsp; &nbsp;{" "}
              <i className="fas fa-arrow-right" aria-hidden="true" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  </section> : null}
  </>
  )
}

export default ScheduleDemo