import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Route, Switch, Redirect } from 'react-router-dom'
const HomePage = React.lazy(() => import('../views/HomePage/Index'))
const GoDigital = React.lazy(() => import('../views/GoDigital/Index'))
const Website = React.lazy(() => import('../views/GoDigital/Website'))
const Seo = React.lazy(() => import('../views/GoDigital/SEO'))
const ManageReputation = React.lazy(() =>
  import('../views/ManageYourReputation/Index'),
)
const Rating = React.lazy(() => import('../views/ManageYourReputation/Rating'))
const Reviews = React.lazy(() =>
  import('../views/ManageYourReputation/Reviews'),
)
const Survey = React.lazy(() => import('../views/ManageYourReputation/Survey'))
const AutoPilotReception = React.lazy(() =>
  import('../views/AutoPilotReception/Index'),
)
const Appointment = React.lazy(() =>
  import('../views/AutoPilotReception/Appointment'),
)
const PaymentCollection = React.lazy(() =>
  import('../views/AutoPilotReception/PaymentCollection'),
)
const PersonalisedGreetings = React.lazy(() =>
  import('../views/AutoPilotReception/PersonalisedGreetings'),
)
const Communicative = React.lazy(() => import('../views/Communicative/Index'))
const CommunicativeBlog = React.lazy(() =>
  import('../views/Communicative/Blog'),
)
const EmailerAndMessaging = React.lazy(() =>
  import('../views/Communicative/EmailerAndMessaging'),
)
const ResearchAndCaseStudies = React.lazy(() =>
  import('../views/Communicative/ResearchAndCaseStudies'),
)
const ModernPatient = React.lazy(() => import('../views/ModernPatient/Index'))
const MarketingCampaigns = React.lazy(() =>
  import('../views/ModernPatient/MarketingCampaigns'),
)
const OnlineScheduling = React.lazy(() =>
  import('../views/ModernPatient/OnlineScheduling'),
)
const Telehealth = React.lazy(() => import('../views/ModernPatient/Telehealth'))
const AnalyticsAndInsights = React.lazy(() =>
  import('../views/AnalyticsAndInsights/Index'),
)
const AllDataInOnePlace = React.lazy(() =>
  import('../views/AnalyticsAndInsights/AllDataInOnePlace'),
)
const CallAnalytics = React.lazy(() =>
  import('../views/AnalyticsAndInsights/CallAnalytics'),
)
const PaymentHistory = React.lazy(() =>
  import('../views/AnalyticsAndInsights/PaymentHistory'),
)
const PracticeAnalytics = React.lazy(() =>
  import('../views/AnalyticsAndInsights/PracticeAnalytics'),
)
const TurnDataIntoInsight = React.lazy(() =>
  import('../views/AnalyticsAndInsights/TurnDataIntoInsight'),
)
const Blogs = React.lazy(() => import('../views/Blogs/Index'))
const BlogDetails = React.lazy(() => import('../views/Blogs/BlogDetails'))
const AboutUs = React.lazy(() => import('../views/AboutUs/Index'))
const ScheduleDemoPage = React.lazy(() =>
  import('../views/ScheduleDemo/ScheduleDemoPage'),
)
const TermConditionPage = React.lazy(() =>
  import('../views/TermCondition/index'),
)
const Policy = React.lazy(() => import('../views/Privacy/index'))
const NotFound = React.lazy(() => import('../views/ErrorPages/NotFound'))

export default function AppRoutes() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [pathname])

  return (
    <Switch>
      <Route path="/" exact={true} component={HomePage} />
      <Route path="/blogs" exact={true} component={Blogs} />
      <Route
        path="/blog/want-genuine-visitors-to-your-website"
        exact={true}
        component={BlogDetails}
      />
      <Route path="/go-digital" exact={true} component={GoDigital} />
      <Route
        path="/go-digital/website-and-mobile-application"
        exact={true}
        component={Website}
      />
      <Route path="/go-digital/seo" exact={true} component={Seo} />
      <Route
        path="/manage-your-reputation"
        exact={true}
        component={ManageReputation}
      />
      <Route
        path="/manage-your-reputation/rating"
        exact={true}
        component={Rating}
      />
      <Route
        path="/manage-your-reputation/reviews"
        exact={true}
        component={Reviews}
      />
      <Route
        path="/manage-your-reputation/survey"
        exact={true}
        component={Survey}
      />
      <Route path="/about-us" exact={true} component={AboutUs} />
      <Route
        path="/auto-pilot-reception"
        exact={true}
        component={AutoPilotReception}
      />
      <Route
        path="/auto-pilot-reception/appointment"
        exact={true}
        component={Appointment}
      />
      <Route
        path="/auto-pilot-reception/payment-collection"
        exact={true}
        component={PaymentCollection}
      />
      <Route
        path="/auto-pilot-reception/personalised-greetings"
        exact={true}
        component={PersonalisedGreetings}
      />
      <Route path="/communicative" exact={true} component={Communicative} />
      <Route
        path="/communicative/blog"
        exact={true}
        component={CommunicativeBlog}
      />
      <Route
        path="/communicative/research-and-case-studies"
        exact={true}
        component={ResearchAndCaseStudies}
      />
      <Route
        path="/communicative/emailer-and-messaging"
        exact={true}
        component={EmailerAndMessaging}
      />
      <Route path="/modern-patient" exact={true} component={ModernPatient} />
      <Route
        path="/modern-patient/marketing-campaigns"
        exact={true}
        component={MarketingCampaigns}
      />
      <Route
        path="/modern-patient/online-scheduling"
        exact={true}
        component={OnlineScheduling}
      />
      <Route
        path="/modern-patient/telehealth"
        exact={true}
        component={Telehealth}
      />
      <Route
        path="/analytics-and-insights"
        exact={true}
        component={AnalyticsAndInsights}
      />
      <Route
        path="/analytics-and-insights/turn-data-into-insight"
        exact={true}
        component={TurnDataIntoInsight}
      />
      <Route
        path="/analytics-and-insights/practice-analytics"
        exact={true}
        component={PracticeAnalytics}
      />
      <Route
        path="/analytics-and-insights/all-data-in-one-place"
        exact={true}
        component={AllDataInOnePlace}
      />
      <Route
        path="/analytics-and-insights/call-analytics"
        exact={true}
        component={CallAnalytics}
      />
      <Route
        path="/analytics-and-insights/payment-history"
        exact={true}
        component={PaymentHistory}
      />
      <Route
        path="/term-and-conditions"
        exact={true}
        component={TermConditionPage}
      />
      <Route path="/privacy-policy" exact={true} component={Policy} />
      <Route path="/schedule-demo" exact={true} component={ScheduleDemoPage} />

      {/* <Redirect from="*" to="/404" /> */}
      <Route path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  )
}
