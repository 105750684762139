import { BrowserRouter } from 'react-router-dom';
import './assets/css/style.css';
import Footer from './Components/Default/Footer';
import AppRoutes from './routes/AppRoutes';
import { Suspense } from 'react';
import MainLoader from './Components/Loaders/MainLoader';
import ScheduleDemo from './Components/Common/ScheduleDemo';
import 'react-responsive-modal/styles.css';
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieSection from './Components/Cookie/CookieSection';

function App() {
  // document.addEventListener('contextmenu', (event) => {
  //   event.preventDefault();
  // });
  return (
    <Provider store={store}>
      <CookieSection />
      <ToastContainer />

      <BrowserRouter>
        <Suspense fallback={<MainLoader />}>
          <AppRoutes />
          <ScheduleDemo />
          <Footer />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
