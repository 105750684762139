import React from "react";

export default function ImageLoader() {
  return (
    <div className="ph-item mx-4 my-0 p-0 border-none">
      <div className="ph-col-12 m-0 p-0 ">
        <div className="ph-row">
          <div
            className="ph-col-12"
            style={{ height: "350px", width: "100%" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
