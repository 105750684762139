import React from 'react'
import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/default_logo.svg'
import ImageLoader from '../Loaders/ImageLoader'
const Image = React.lazy(() => import('../Common/Image'))

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row footer_row_list_li">
          <div className="col-sm-3">
            <ul className="footer_listing_ul">
              <li className="big_call_listing_footer">
                <Link to="/go-digital/">
                  <span>Go Digital</span>
                </Link>
                <ul className="sub_samle_manue">
                  <li>
                    <Link to="/go-digital/website-and-mobile-application">
                      Website
                    </Link>
                  </li>
                  <li>
                    <Link to="/go-digital/seo">SEO</Link>
                  </li>
                  {/* <li><Link to="#">Social Listening</Link></li> */}
                </ul>
              </li>
              <li className="big_call_listing_footer">
                <Link to="/manage-your-reputation/">
                  <span>Manage Your Reputation</span>
                </Link>
                <ul className="sub_samle_manue">
                  <li>
                    <Link to="/manage-your-reputation/rating">Rating</Link>
                  </li>
                  <li>
                    <Link to="/manage-your-reputation/reviews">Reviews</Link>
                  </li>
                  <li>
                    <Link to="/manage-your-reputation/survey">Survey</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-4">
            <ul className="footer_listing_ul">
              <li className="big_call_listing_footer">
                <Link to="/auto-pilot-reception/">
                  <span>Auto Pilot Reception</span>
                </Link>
                <ul className="sub_samle_manue">
                  <li>
                    <Link to="/auto-pilot-reception/appointment">
                      Appointment Confirmation and Reminders
                    </Link>
                  </li>
                  <li>
                    <Link to="/auto-pilot-reception/payment-collection">
                      Payment collection
                    </Link>
                  </li>
                  <li>
                    <Link to="/auto-pilot-reception/personalised-greetings">
                      Personalised Greetings
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="big_call_listing_footer">
                <Link to="/communicative/">
                  <span>Communicative</span>
                </Link>
                <ul className="sub_samle_manue">
                  <li>
                   <a href="https://blog.prakey.com/" target="_blank">Blog</a>
                  </li>
                  <li>
                    <Link to="/communicative/research-and-case-studies">
                      Research and Case Studies
                    </Link>
                  </li>
                  <li>
                    <Link to="/communicative/emailer-and-messaging">
                      Emailer and Messaging
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="footer_listing_ul">
              <li className="big_call_listing_footer">
                <Link to="/analytics-and-insights/">
                  <span>Analytics and Insights</span>
                </Link>
                <ul className="sub_samle_manue">
                  <li>
                    <Link to="/analytics-and-insights/turn-data-into-insight">
                      Turn Data into insight
                    </Link>
                  </li>
                  <li>
                    <Link to="/analytics-and-insights/practice-analytics">
                      Practice Analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="/analytics-and-insights/all-data-in-one-place">
                      All data in one place
                    </Link>
                  </li>
                  <li>
                    <Link to="/analytics-and-insights/call-analytics">
                      Call Analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="/analytics-and-insights/payment-history">
                      Payment History
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <ul className="footer_listing_ul">
              <li className="big_call_listing_footer">
                <Link to="/modern-patient/">
                  <span> Modern Patient</span>
                </Link>
                <ul className="sub_samle_manue">
                  <li>
                    <Link to="/modern-patient/online-scheduling">
                      Online Scheduling
                    </Link>
                  </li>
                  <li>
                    <Link to="/modern-patient/telehealth">Tele Health</Link>
                  </li>
                  <li>
                    <Link to="/modern-patient/marketing-campaigns">
                      Marketing Campaign
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="row_align_items align-items">
          <div className="col-sm-6">
            <Suspense
              fallback={
                <div className="text-center w-100">
                  <ImageLoader />
                </div>
              }
            >
              <Link title="Prakey Earn Trust" to="/" className="logo">
                <Image src={logo} alt={'Prakey Earn Trust'} />
              </Link>
            </Suspense>
            <div className="copy-right">
              A Unit of Virinchi Software Pvt Ltd
            </div>
          </div>
          <div className="col-sm-6">
            <ul className="foot-link">
            <li>
              <a href="https://heart.prakey.com/" target="_blanck">Login</a>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/schedule-demo" data-toggle="modal" data-target="#DemoQeryModal">
                  Contact Us
                </Link>
              </li>
              <li>
                <a href="https://blog.prakey.com/" target="_blank">Blog</a>
              </li>
              <li>
                <Link to="/term-and-conditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
            <div className="clearfix" />
            <div className="copy-right copy_right_algin_right">
              Copyright © 2023 Prakey
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
