import React from "react";
import { BeatLoader } from "react-spinners";

export default function MainLoader() {
  return (
    <div className="main-loader">
      <BeatLoader color={"#18a065"} loading={true} size={20} />
    </div>
  );
}
